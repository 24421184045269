import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ForgetPassword from 'components/authentication/simple/ForgetPassword';
import { refreshToken, getAccessToken } from 'components/utilities/sedra3/auth';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 403) {
    try {
      const newToken = await refreshToken();
      // Retry the original query with the new token
      if (newToken) {
        const retryArgs = {
          ...args,
          headers: {
            ...(args.headers || {}),
            Authorization: `Bearer ${newToken}`,
          },
        };

        result = await baseQuery(retryArgs, api, extraOptions);
      }
    } catch (error) {
      console.error('Token refresh failed', error);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
    }
  }

  return result;
};

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    getBanks: builder.query({
      query: () => '/bank/list',
    }),
    getRolesByBank: builder.query({
      query: (bankId) => ({
        url: `/role/${bankId}/roles`,
        method: 'GET',
        headers: {
          // Add any custom headers here if needed
        }
      })
    }),
    registerUser: builder.mutation({
      query: (userDetails) => ({
        url: '/user',
        method: 'POST',
        body: userDetails,
      }),
    }),
    resetPassword: builder.mutation({
      query: (passwordResetData) => ({
        url: '/login/reset-password',
        method: 'POST',
        body: passwordResetData,
      }),
    }),
    passwordResetRequest: builder.mutation({
      query: (email) => ({
        url: '/login/password-reset-request',
        method: 'POST',
        body: { email },
      }),
    }),
    changePassword: builder.mutation({
      query: (passwordChangeData) => ({
        url: "/login/change_password",
        method: "POST",
        body: passwordChangeData
      }),
    }),
  }),
});


export const {
  useLoginUserMutation,
  useGetRolesQuery,
  useRegisterUserMutation,
  useGetBanksQuery,
  useGetRolesByBankQuery,
  useResetPasswordMutation,
  usePasswordResetRequestMutation,
  useChangePasswordMutation
} = authApi;
