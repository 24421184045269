import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import WizardInput from './WizardInput';

const PersonalForm = ({ register, errors, setValue, user, rolesData, rolesLoading }) => {
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (user) {
      setValue('first_name', user.first_name);
      setValue('last_name', user.last_name);
      setValue('phone', user.phone);

      // Set initial roles (only active roles)
      const initialRoles = user.roles
        .filter(role => role.status === 'Active')
        .map(role => ({
          value: role.id,
          label: role.name,
        }));
      console.log(`initialRoles ${initialRoles}`)

      setSelectedRoles(initialRoles);
      setValue('roles', initialRoles);
    }
  }, [user, setValue]);

  const handleRolesChange = (newSelectedRoles) => {
    setSelectedRoles(newSelectedRoles);
    setValue('roles', newSelectedRoles);
  };

  return (
    <>
      <WizardInput
        type="text"
        label="First Name"
        name="first_name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{ ...register('first_name') }}
      />

      <WizardInput
        type="text"
        label="Last Name"
        name="last_name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{ ...register('last_name') }}
      />

      <WizardInput
        type="number"
        label="Phone"
        name="phone"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{ className: 'input-spin-none', ...register('phone') }}
      />

      <Form.Group className="mb-3">
        <Form.Label>Roles</Form.Label>
        <Select
          isMulti
          name="roles"
          options={rolesData?.map(role => ({
            value: role.id,
            label: role.name,
          }))}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select roles"
          onChange={handleRolesChange}
          value={selectedRoles}
          isDisabled={rolesLoading}
        />
        {errors.roles && <div className="invalid-feedback d-block">{errors.roles.message}</div>}
      </Form.Group>
    </>
  );
};

PersonalForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  rolesData: PropTypes.array.isRequired,
  rolesLoading: PropTypes.bool.isRequired
};

export default PersonalForm;
