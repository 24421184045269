import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Avatar from "components/common/Avatar";
import paths from "routes/paths";
import Authorized from "components/utilities/sedra3/rolesAuth";
import { useLazyViewDocumentQuery } from "components/services/users/userService";
import { getAccessToken } from "components/utilities/sedra3/auth";
import { toast } from "react-toastify";
import { fileDownolader } from "components/utilities/sedra3/file-downloader";

const getUserInitials = (firstName, lastName) => {
  return (firstName[0] + (lastName ? lastName[0] : "")).toUpperCase();
};

const ProfileDropdown = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const initials = user ? getUserInitials(user.first_name, user.last_name) : "U";

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate(paths.cardLogout);
  };

  const handleViewDocument = async () => {
    const { data } = await triggerViewDocument();
    if (data) {
      window.open(data, "_blank"); // Open the PDF in a new tab
    }
  };

  const handleDownloadCalculationTemplates = async () => {
    try {
      const token = getAccessToken(); // Get the auth token
      const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/user/view-calculation`;
      await fileDownolader(downloadUrl, token);
    } catch (error) {
      toast.error("Failed to download calculation templates: " + error.message);
    }
  };

  const [triggerViewDocument] = useLazyViewDocumentQuery();

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!" className="pe-0 ps-2 nav-link">
        <Avatar>{initials}</Avatar>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item onClick={handleViewDocument} key="user-manual">
            User manual
          </Dropdown.Item>
          {/* New Dropdown Item for Calculation Templates */}
          <Dropdown.Item onClick={handleDownloadCalculationTemplates} key="calculation-templates">
            Calculation templates
          </Dropdown.Item>
          <Dropdown.Divider key="user-manual-divider" />
          <Dropdown.Item as={Link} to={paths.userProfile} key="profile-account">
            Profile &amp; account
          </Dropdown.Item>
          <Authorized roles={["Portal Administrator", "Bank Administrator"]}>
            <Dropdown.Item as={Link} to={paths.userManagement} key="user-management">
              Users Management
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={paths.callApplication} key="call-application">
              Calls for Applications
            </Dropdown.Item>
          </Authorized>
          <Dropdown.Item as={Link} to={paths.feedback} key="feedback">
            Feedback
          </Dropdown.Item>
          <Dropdown.Divider key="divider" />
          <Dropdown.Item as={Link} to="#!" onClick={handleLogout} key="logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
