import React from "react";
import { useSelector } from "react-redux";

import CustomerLayout from "./CustomerLayout";
import PortalAdminLayout from "./PortalAdminLayout";
import ApplicationSpecialistLayout from "./ApplicationSpecialistLayout";
import RoleTabNavigation from "./RoleTabNavigation";

const MainLayoutSelector = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  if (user) {
    const tabs = [];

    // Check if the user is a Portal Administrator
    if (user.roles.some((role) => role.name === "Portal Administrator")) {
      // Add a tab for Portal Administrator
      tabs.push({
        name: "Portal Admin", // The name of the tab that will be displayed
        component: <PortalAdminLayout>{children}</PortalAdminLayout>, // The layout that will render when this tab is selected
      });
    }

    // Check if the user is an Applications Specialist
    if (user.roles.some((role) => role.name === "Applications Specialist")) {
      // Add a tab for Applications Specialist
      tabs.push({
        name: "Application Specialist", // The name of the tab
        component: <ApplicationSpecialistLayout>{children}</ApplicationSpecialistLayout>, // The layout for this tab
      });
    }

    // If the user has at least one role, render the tab navigation
    if (tabs.length > 0) {
      return <RoleTabNavigation tabs={tabs} />;
    }

    // If the user has no specific roles, default to CustomerLayout
    return <CustomerLayout>{children}</CustomerLayout>;
  }

  return null; // Or a fallback layout or loading indicator
};

export default MainLayoutSelector;
