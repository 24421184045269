import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Spinner, Button, Modal, Form } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { useGetUserDetailQuery, useUpdateUserProfileMutation, useDeactivateUserMutation } from 'components/services/users/userService';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PersonalForm from 'components/wizard/PersonalForm';
import { useGetRolesByBankQuery } from 'components/services/authorization/authService';
import { toast } from 'react-toastify';
import PasswordChangeForm from 'components/authentication/PasswordChangeForm';
import SubtleBadge from 'components/common/SubtleBadge';

const Profile = () => {
  const authState = useSelector((state) => state.auth);
  const userId = authState.user?.id;
  const { data: responseData, error, isLoading, refetch } = useGetUserDetailQuery(userId);
  const [updateUserProfile, { isLoading: isUpdating }] = useUpdateUserProfileMutation();
  const [deactivateUser] = useDeactivateUserMutation();

  const user = responseData?.data;
  const { data: rolesData, error: rolesError, isLoading: rolesLoading } = useGetRolesByBankQuery(user?.bank_id, {
    skip: !user?.bank_id,
  });

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const handleShowProfileModal = () => setShowProfileModal(true);
  const handleCloseProfileModal = () => setShowProfileModal(false);

  const handleShowPasswordModal = () => setShowPasswordModal(true);
  const handleClosePasswordModal = () => setShowPasswordModal(false);

  const handleShowDeactivateModal = () => setShowDeactivateModal(true);
  const handleCloseDeactivateModal = () => setShowDeactivateModal(false);

  useEffect(() => {
    if (user) {
      setValue('first_name', user.first_name);
      setValue('last_name', user.last_name);
      setValue('email', user.email);
      setValue('phone', user.phone);
      setValue('bank', user.bank?.name);
      setValue('status', user.status);
      setValue('roles', user.roles.map(role => ({ value: role.role_id, label: role.role_name })));
    }
  }, [user, setValue]);

  const onSubmit = async (data) => {
    try {
      const userUpdateData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        bank: data.bank,
        status: data.status,
        role_ids: data.roles ? data.roles.map(role => role.value) : [], // Ensure roles is not null
      };

      await updateUserProfile(userUpdateData).unwrap();
      toast.success('User profile updated successfully.', { theme: 'colored' });
      refetch();
      handleCloseProfileModal();
    } catch (error) {
      toast.error(`Failed to update profile: ${error.data?.detail}`, { theme: 'colored' });
    }
  };

  const handleDeactivate = async () => {
    try {
      await deactivateUser({ user_id: userId }).unwrap();
      toast.success('User deactivated successfully.', { theme: 'colored' });
      handleCloseDeactivateModal();
      refetch();
    } catch (error) {
      toast.error(`Failed to deactivate user: ${error.data?.detail}`, { theme: 'colored' });
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <div>Error loading user data</div>;
  }

  if (!user) {
    return <div>No user data available</div>;
  }

  const statusColors = {
    Inactive: 'secondary',
    'Pending Role Change Confirmation': 'warning',
    Active: 'success',
    Rejected: 'danger'
  };

  const filteredRoles = user.roles?.filter(role =>
    role.status === 'Active' || role.status === 'Pending Role Change Confirmation'
  ) || [];

  const isInactive = user.status === 'Inactive';

  return (
    <>
      <FalconComponentCard className="mb-0">
        <FalconComponentCard.Header
          title="User Profile"
          light={false}
          className="border-bottom border-200"
        />
        <FalconComponentCard.Body>
          <Row>
            <Col md={4}>
              <strong>Name:</strong>
            </Col>
            <Col md={8}>
              {user.first_name} {user.last_name}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <strong>Email:</strong>
            </Col>
            <Col md={8}>
              {user.email}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <strong>Bank:</strong>
            </Col>
            <Col md={8}>
              {user.bank.name}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <strong>Status:</strong>
            </Col>
            <Col md={8}>
              <SubtleBadge pill bg={statusColors[user.status]}>
                <span>{user.status}</span>
              </SubtleBadge>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <strong>Roles:</strong>
            </Col>
            <Col md={8}>
              {filteredRoles.map((role, index) => (
                <React.Fragment key={`${role.role_id}-${index}`}>
                  <span>{role.name} </span>
                  <SubtleBadge pill bg={statusColors[role.status]}>
                    {role.status.length > 9 ? `${role.status.substring(0, 9)}...` : role.status}
                  </SubtleBadge>
                  {index < filteredRoles.length - 1 && ', '}
                </React.Fragment>
              ))}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-start">
              <Button variant="outline-danger" onClick={handleShowDeactivateModal} disabled={isInactive}>
                Deactivate Account
              </Button>
            </Col>
            <Col className="text-end">
              <Button variant="outline-primary" onClick={handleShowProfileModal} disabled={isInactive}>
                Update Profile
              </Button>
              <Button variant="link" onClick={handleShowPasswordModal} disabled={isInactive}>
                Change Password
              </Button>
            </Col>
          </Row>
        </FalconComponentCard.Body>
      </FalconComponentCard>

      <Modal show={showProfileModal} onHide={handleCloseProfileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <PersonalForm
              register={register}
              errors={errors}
              setValue={setValue}
              user={user}
              rolesData={rolesData || []}
              rolesLoading={rolesLoading}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseProfileModal}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit(onSubmit)} disabled={isUpdating}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PasswordChangeForm hasLabel handleClose={handleClosePasswordModal} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeactivateModal} onHide={handleCloseDeactivateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Deactivate Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to deactivate your account? This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeactivateModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeactivate}>
            Deactivate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Profile;
