import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';

import { useAppContext } from 'providers/AppProvider';

const PortalAdminLayout = () => {
  const { hash, pathname } = useLocation();
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid }
  } = useAppContext();

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <NavbarTop />
      {/*------ Main Routes ------*/}
      <Outlet />
    </div>
  );
};

export default PortalAdminLayout;
