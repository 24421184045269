import React from 'react';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import AppProvider from 'providers/AppProvider';
import { router } from 'routes';
import 'helpers/initFA';


const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </Provider>
  </React.StrictMode>
);
