import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateFeedbackMutation } from "components/services/feedback/feedbackService";
import { rootPaths } from "routes/paths";

const Feedback = () => {
  const [createFeedback, { isLoading: isCreating }] = useCreateFeedbackMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === `/${rootPaths.userRoot}/feedback`) {
      setShowModal(true);
    }
  }, [location]);

  const handleCloseModal = () => {
    setShowModal(false);
    reset();
    navigate(-1); // Navigate back to the previous page
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("comments", data.comments);
    formData.append("uploaded_file", uploadedFile);

    try {
      await createFeedback(formData).unwrap();
      toast.success("Feedback sent successfully.", { theme: "colored" });
      handleCloseModal();
    } catch (error) {
      toast.error(`Failed to sent feedback: ${error.data?.detail}`, { theme: "colored" });
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered className="call-application-modal">
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>Comments</Form.Label>
              <Form.Control as="textarea" rows={3} {...register("comments", { required: true })} />
              {errors.comments && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Add file, if necessary</Form.Label>
              <Form.Control type="file" onChange={(e) => setUploadedFile(e.target.files[0])} />
              {errors.uploaded_file && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Modal.Footer className="call-application-modal">
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={isCreating}>
                Send
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Feedback;
