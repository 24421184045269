// src/messages.js

const messages = {
    // Error messages
    PASSWORDS_DO_NOT_MATCH: "Passwords do not match",
    PASSWORD_RESET_FAILURE: "Failed to reset password. Please try again.",
    
    // Success messages
    PASSWORD_RESET_SUCCESS: "Password has been successfully reset. Please log in with your new password.",
    
    // Placeholder messages
    PASSWORD_PLACEHOLDER: "New Password",
    CONFIRM_PASSWORD_PLACEHOLDER: "Confirm Password",
  
    // Messages with placeholders
    WELCOME_USER: (userName) => `Welcome, ${userName}!`,
    PASSWORD_RESET_EMAIL_SENT: (userName) => `Hello, ${userName}. A password reset link has been sent to your email.`,
    PASSWORD_RESET_FAILURE_DETAIL: (error) => `Failed to reset password: ${error}`,
  };

export default messages;
