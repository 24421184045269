import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../authorization/authService';

export const commentApi = createApi({
	reducerPath: 'commentApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Comment'],
	endpoints: (builder) => ({
		getCommentsByApplicationId: builder.query({
			query: (applicationId) => ({
				url: `/comment/application/${applicationId}`,
				method: 'GET',
			}),
			providesTags: (result, error, applicationId) => [{ type: 'Comment', applicationId }],
		}),
		createComment: builder.mutation({
			query: (commentData) => ({
				url: `/comment`,
				method: 'POST',
				body: commentData,
			}),
			invalidatesTags: (result, error, { application_id }) => [{ type: 'Comment', application_id }],
		}),
		updateComment: builder.mutation({
			query: ({ comment_id, commentData }) => ({
				url: `/comment/${comment_id}`,
				method: 'PUT',
				body: commentData,
			}),
			invalidatesTags: (result, error, { application_id }) => [{ type: 'Comment', application_id }],
		}),
		deleteComment: builder.mutation({
			query: ({ comment_id }) => ({
				url: `/comment/${comment_id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, { application_id }) => [{ type: 'Comment', application_id }],
		}),
	}),
});

export const {
	useGetCommentsByApplicationIdQuery,
	useCreateCommentMutation,
	useUpdateCommentMutation,
	useDeleteCommentMutation,
} = commentApi;
