import React, { useState } from "react";
import { Card, Form, Button, Collapse } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useCreateCommentMutation,
  useUpdateCommentMutation,
} from "components/services/comment/commentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faReply, faEdit } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const pastelColors = [
  "#AEC6CF",
  "#FFB347",
  "#77DD77",
  "#FF6961",
  "#FDFD96",
  "#C23B22",
  "#B39EB5",
  "#CB99C9",
  "#FFD1DC",
];

const getColorFromUserId = (userId) => {
  const index = userId % pastelColors.length;
  return pastelColors[index];
};

const getUserInitials = (email) => {
  const [first, second] = email.split("@")[0].split(".");
  return (first[0] + (second ? second[0] : "")).toUpperCase();
};

const Comment = ({
  comment,
  applicationId,
  refetchComments,
  isReply = false,
  level = 0,
  isDetail, // Add isDetail prop
}) => {
  const { user } = useSelector((state) => state.auth);
  const [showReply, setShowReply] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [editContent, setEditContent] = useState(comment.content);
  const [showReplies, setShowReplies] = useState(false);
  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();

  const handleReply = async () => {
    if (replyContent.trim() !== "") {
      await createComment({
        content: replyContent,
        parent_id: comment.id,
        application_id: applicationId,
      });
      setReplyContent("");
      setShowReply(false);
      setShowReplies(true);
      refetchComments();
    }
  };

  const handleEdit = async () => {
    if (editContent.trim() !== "") {
      await updateComment({
        comment_id: comment.id,
        commentData: { content: editContent },
      });
      setShowEdit(false);
      refetchComments();
    }
  };

  const userColor = getColorFromUserId(comment.created_by.id);

  return (
    <div className={`mb-2 p-2 ${isReply ? "ms-4" : ""}`}>
      <div className="d-flex align-items-start">
        <div
          className="me-2"
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            backgroundColor: userColor,
            color: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          {getUserInitials(comment.created_by.email)}
        </div>
        <div className="flex-grow-1">
          <div>
            <strong className={user.id === comment.created_by.id ? "text-success-emphasis" : ""}>
              {comment.created_by.email}
            </strong>{" "}
            - <small>{new Date(comment.created_at).toLocaleString()}</small>
          </div>
          {!showEdit ? (
            <div className="mt-2">{comment.content}</div>
          ) : (
            <Form className="mt-2" onSubmit={(e) => e.preventDefault()}>
              <Form.Control
                as="textarea"
                rows={2}
                value={editContent}
                onChange={(e) => setEditContent(e.target.value)}
                placeholder="Edit your comment..."
              />
              <Button size="sm" variant="primary" className="mt-2" onClick={handleEdit}>
                Save
              </Button>
            </Form>
          )}
          {!isDetail && ( // Disable reply and edit buttons in detail mode
            <div className="mt-1 d-flex justify-content-start align-items-center">
              {level < 5 && (
                <Button size="sm" variant="link" onClick={() => setShowReply(!showReply)}>
                  <FontAwesomeIcon icon={faReply} /> reply
                </Button>
              )}
              {user.id === comment.created_by.id && (
                <>
                  <Button size="sm" variant="link" onClick={() => setShowEdit(!showEdit)}>
                    <FontAwesomeIcon icon={faEdit} /> edit
                  </Button>
                </>
              )}
            </div>
          )}
          <Collapse in={showReply}>
            <Form className="mt-2" onSubmit={(e) => e.preventDefault()}>
              <Form.Control
                as="textarea"
                rows={2}
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
                placeholder="Write a reply..."
              />
              <Button size="sm" variant="primary" className="mt-2" onClick={handleReply}>
                Submit
              </Button>
            </Form>
          </Collapse>
          {comment.replies && comment.replies.length > 0 && (
            <div className="mt-1">
              <Button size="sm" variant="link" onClick={() => setShowReplies(!showReplies)}>
                <FontAwesomeIcon icon={showReplies ? faChevronDown : faChevronRight} />{" "}
                {comment.replies.length} {comment.replies.length === 1 ? "reply" : "replies"}
              </Button>
              <Collapse in={showReplies}>
                <div>
                  {comment.replies
                    .slice()
                    .reverse()
                    .map((reply) => (
                      <Comment
                        key={reply.id}
                        comment={reply}
                        applicationId={applicationId}
                        refetchComments={refetchComments}
                        isReply={true}
                        level={level + 1}
                        isDetail={isDetail} // Pass isDetail prop to child comments
                      />
                    ))}
                </div>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  applicationId: PropTypes.number.isRequired,
  refetchComments: PropTypes.func.isRequired,
  isReply: PropTypes.bool,
  level: PropTypes.number,
  isDetail: PropTypes.bool.isRequired, // Add prop type for isDetail
};

const CommentTree = ({ comments, applicationId, refetchComments, isDetail }) => {
  const { user } = useSelector((state) => state.auth);
  const [newCommentContent, setNewCommentContent] = useState("");
  const [createComment] = useCreateCommentMutation();

  const handleNewComment = async () => {
    if (newCommentContent.trim() !== "") {
      await createComment({
        content: newCommentContent,
        parent_id: null,
        application_id: applicationId,
      });
      setNewCommentContent("");
      refetchComments();
    }
  };

  return (
    <Card className="mt-4 custom-comment-card">
      <Card.Body>
        <h5>Comments</h5>
        {!isDetail && ( // Disable adding new comments in detail mode
          <Form className="mb-4" onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              as="textarea"
              rows={2}
              value={newCommentContent}
              onChange={(e) => setNewCommentContent(e.target.value)}
              placeholder="Write a new comment..."
            />
            <div className="d-flex justify-content-end">
              <Button size="sm" variant="primary" className="mt-2" onClick={handleNewComment}>
                Add Comment
              </Button>
            </div>
          </Form>
        )}
        <div className="mt-3">
          {comments
            .slice()
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((comment) => (
              <Comment
                key={comment.id}
                comment={comment}
                applicationId={applicationId}
                refetchComments={refetchComments}
                isDetail={isDetail} // Pass isDetail prop to Comment component
              />
            ))}
        </div>
      </Card.Body>
    </Card>
  );
};

CommentTree.propTypes = {
  comments: PropTypes.array.isRequired,
  applicationId: PropTypes.number,
  refetchComments: PropTypes.func.isRequired,
  isDetail: PropTypes.bool.isRequired, // Add prop type for isDetail
};

export default CommentTree;
