import { jwtDecode } from "jwt-decode";


export const getAccessToken = () => localStorage.getItem('accessToken');

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const setAccessToken = (token) => localStorage.setItem('accessToken', token);

export const setRefreshToken = (token) => localStorage.setItem('refreshToken', token);

export const isTokenExpired = (token) => {
  if (!token) return true;
  const { exp } = jwtDecode(token);
  if (!exp) return true;
  return Date.now() >= exp * 1000;
};

export const refreshToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken || isTokenExpired(refreshToken)) {
    throw new Error('Refresh token expired');
  }

  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login/new_access_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh_token: refreshToken }),
  });

  if (!response.ok) {
    throw new Error('Failed to refresh token');
  }

  const data = await response.json();
  const { access_token } = data.data;


  setAccessToken(access_token);

  return access_token;
};
