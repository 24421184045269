import React, { useMemo, useState, useEffect } from "react";
import {
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Form,
  Tooltip,
  OverlayTrigger,
  Dropdown,
} from "react-bootstrap";
import SubtleBadge from "components/common/SubtleBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useGetCallApplicationsQuery,
  useUpdateCallApplicationMutation,
  useCreateCallApplicationMutation,
  useUpdateCallApplicationStatusMutation,
} from "components/services/call-application/CallApplicationService";

const CallApplication = () => {
  // State variables
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentApplication, setCurrentApplication] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [statusUpdateTaskId, setStatusUpdateTaskId] = useState(null); // Track task ID
  const [taskStatus, setTaskStatus] = useState(null); // WebSocket task status

  // Variables for labels and messages
  const modelName = "Calls for Application";
  const modalTitle = isEditMode ? `Edit ${modelName}` : `New ${modelName}`;
  const callApplicationStatus = "Status";
  const startDateLabel = "Start Date";
  const endDateLabel = "Submission";
  const minAmountLabel = "Min Amount";
  const maxAmountLabel = "Max Amount";
  const amountLabel = "Amount";
  const currencyLabel = "Currency";
  const loanTenor = "Loan tenor";
  const maturityLabel = "Maturity, months";
  const graceLabel = "Grace, months";
  const buttonText = "Save Changes";
  const closeButtonText = "Close";
  const newButtonText = "New";
  const editTooltipText = "Edit";
  const loadingText = `Error loading ${modelName}s`;
  const successUpdateMessage = `${modelName} updated successfully.`;
  const successCreateMessage = `${modelName} created successfully.`;
  const errorMessage = `Failed to ${isEditMode ? "update" : "create"} ${modelName}:`;

  const { data: responseData, error, isLoading, refetch } = useGetCallApplicationsQuery();
  const [updateCallApplication, { isLoading: isUpdating }] = useUpdateCallApplicationMutation();
  const [createCallApplication, { isLoading: isCreating }] = useCreateCallApplicationMutation();
  const [updateCallApplicationStatus] = useUpdateCallApplicationStatusMutation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleCloseModal = () => {
    setShowModal(false);
    reset();
  };

  const handleEdit = (application) => {
    setCurrentApplication(application);
    setStartDate(application.start_date ? new Date(application.start_date) : null);
    setEndDate(application.end_date ? new Date(application.end_date) : null);
    setValue("min_amnt", application.min_amnt);
    setValue("max_amnt", application.max_amnt);
    setValue("currency", application.currency);
    setValue("maturity", application.maturity);
    setValue("grace", application.grace);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleCreateNew = () => {
    setIsEditMode(false);
    setShowModal(true);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onSubmit = async (data) => {
    data.start_date = startDate ? formatDate(startDate) : null;
    data.end_date = endDate ? formatDate(endDate) : null;
    if (startDate && endDate && endDate < startDate) {
      toast.warning(`${endDateLabel} cannot be earlier than ${startDateLabel}`, {
        theme: "colored",
      });
      return;
    }
    try {
      if (isEditMode && currentApplication) {
        await updateCallApplication({
          id: currentApplication.id,
          call_application_in: data,
        }).unwrap();
        toast.success(successUpdateMessage, { theme: "colored" });
      } else {
        const response = await createCallApplication(data).unwrap();
        toast.success(successCreateMessage, { theme: "colored" });
      }
      refetch();
      handleCloseModal();
    } catch (error) {
      toast.error(`${errorMessage} ${error.data?.detail}`, { theme: "colored" });
    }
  };

  useEffect(() => {
    if (statusUpdateTaskId) {
      const ws = new WebSocket(`${process.env.REACT_APP_WS_BASE_URL}/ws/task-status/${statusUpdateTaskId}`);

      ws.onmessage = (event) => {
        const message = event.data;
        setTaskStatus(message);
        if (message.includes("successfully")) {
          toast.success(message, { theme: "colored" });
          ws.close();
        } else if (message.includes("failed")) {
          toast.error(message, { theme: "colored" });
          ws.close();
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = () => {
        console.log("WebSocket closed");
      };

      return () => {
        ws.close();
      };
    }
  }, [statusUpdateTaskId]);

  const handleStatusAction = async (callAppId, newStatus) => {
    console.log("callAppId", callAppId);
    try {
      const response = await updateCallApplicationStatus({
        id: callAppId,
        new_status: newStatus,
      }).unwrap();
      console.log("response.task_id ", response);
      setStatusUpdateTaskId(response.task_id);
      refetch();
      toast.success(`Status updated to ${newStatus}`, { theme: "colored" });
    } catch (error) {
      toast.error(`Failed to update status: ${error.data?.detail}`, { theme: "colored" });
    }
  };

  const getStatusBadge = (status, callAppId) => {
    if (status === "Completed") {
      return (
        <SubtleBadge pill bg="primary">
          {status}
        </SubtleBadge>
      );
    }

    return (
      <Dropdown>
        <Dropdown.Toggle variant="link" className="p-0">
          <SubtleBadge pill bg={status === "Completed" ? "primary" : "success"}>
            {status}
          </SubtleBadge>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleStatusAction(callAppId, "Completed")}>
            <FontAwesomeIcon icon={faCheckSquare} className="me-2 text-success" /> Complete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Memoizing the modified data
  const modifiedData = useMemo(() => {
    return responseData
      ? responseData.data.items.map((item) => ({
          ...item,
          amnt: `${item.min_amnt}-${item.max_amnt}`, // Combining min_amnt and max_amnt
        }))
      : [];
  }, [responseData]);

  const columns = [
    {
      accessorKey: "status",
      header: callApplicationStatus,
      cell: ({ row: { original } }) => getStatusBadge(original.status, original.id),
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "start_date",
      header: startDateLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "end_date",
      header: endDateLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "amnt",
      header: amountLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "currency",
      header: currencyLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "maturity",
      header: loanTenor,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "grace",
      header: graceLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      id: "action",
      header: "Action",
      cell: ({ row: { original } }) =>
        original.status !== "Completed" ? (
          <OverlayTrigger overlay={<Tooltip>{editTooltipText}</Tooltip>}>
            <div className="d-flex justify-content-center">
              <Button variant="link" size="sm" onClick={() => handleEdit(original)}>
                <FontAwesomeIcon icon="edit" />
              </Button>
            </div>
          </OverlayTrigger>
        ) : null,
      meta: {
        headerProps: { className: "text-900 text-start" },
      },
    },
  ];

  const table = useAdvanceTable({
    data: modifiedData,
    columns,
    sortable: true,
    pagination: true,
    perPage: 5,
  });

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <div>{loadingText}</div>;
  }

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header
          title={`${modelName}s`}
          light={false}
          className="border-bottom border-200"
        ></FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <AdvanceTableProvider {...table}>
            <Row className="center mt-1 mb-4">
              <Col xs="auto" sm={6} lg={4} className="text-start">
                <AdvanceTableSearchBox />
              </Col>
              <Col xs="auto" sm={6} lg={4} className="text-end ms-auto">
                <Button
                  type="button"
                  variant="falcon-default"
                  size="sm"
                  className="ms-2"
                  onClick={handleCreateNew}
                >
                  <FontAwesomeIcon icon="plus" className="me-2" />
                  {newButtonText}
                </Button>
              </Col>
            </Row>
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
            </div>
          </AdvanceTableProvider>
        </FalconComponentCard.Body>
      </FalconComponentCard>

      <Modal show={showModal} onHide={handleCloseModal} centered className="call-application-modal">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>{startDateLabel}</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
              {errors.start_date && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{endDateLabel}</Form.Label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
              {errors.end_date && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{minAmountLabel}</Form.Label>
              <Form.Control type="number" {...register("min_amnt", { required: true })} />
              {errors.min_amnt && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{maxAmountLabel}</Form.Label>
              <Form.Control type="number" {...register("max_amnt", { required: true })} />
              {errors.max_amnt && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{currencyLabel}</Form.Label>
              <Form.Control
                type="text"
                {...register("currency", { required: true })}
                defaultValue="Nepali rupee"
                readOnly
              />
              {errors.currency && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{loanTenor}</Form.Label>
              <Form.Control type="number" {...register("maturity", { required: true })} />
              {errors.maturity && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{graceLabel}</Form.Label>
              <Form.Control type="number" {...register("grace", { required: true })} />
              {errors.grace && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Modal.Footer className="call-application-modal">
              <Button variant="secondary" onClick={handleCloseModal}>
                {closeButtonText}
              </Button>
              <Button variant="primary" type="submit" disabled={isUpdating || isCreating}>
                {buttonText}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CallApplication;
