import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Row, Col } from "react-bootstrap";
import {
  useGetRolesByBankQuery,
  useGetBanksQuery,
  useRegisterUserMutation,
} from "components/services/authorization/authService";
import paths from "routes/paths";
import Select from "react-select";

const RegistrationForm = ({ hasLabel }) => {
  const navigate = useNavigate();
  // State
  const [errors, setErrors] = useState({});
  const [captchaData, setCaptchaData] = useState({ captchaUrl: "", captchaId: "" });
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    telephone: "",
    confirmPassword: "",
    isAccepted: false,
    bankId: "",
    roles: [],
  });

  const dashes = "--------";

  const [registerUser] = useRegisterUserMutation();
  const { data: banksData, error: banksError, isLoading: banksLoading } = useGetBanksQuery();
  const {
    data: rolesData,
    error: rolesError,
    isLoading: rolesLoading,
    refetch: refetchRoles,
  } = useGetRolesByBankQuery(formData.bankId, {
    skip: !formData.bankId,
  });

  const fetchCaptcha = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login/captcha`);
      const data = await response.json();
      setCaptchaData({
        captchaUrl: `data:image/png;base64,${data.image}`, // Assuming image is sent as base64 encoded
        captchaId: data.captcha_id,
      });
      setCaptchaError("");
    } catch (error) {
      console.error("Failed to fetch CAPTCHA:", error);
      toast.error("Failed to load CAPTCHA. Please try again.");
    }
  };

  useEffect(() => {
    fetchCaptcha();
  }, []);

  useEffect(() => {
    if (formData.bankId) {
      refetchRoles();
    }
  }, [formData.bankId, refetchRoles]);

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    setCaptchaError("");

    // You would typically send a request to the backend to validate the CAPTCHA here
    // Assume function validateCaptcha() that returns true if CAPTCHA is correct
    const captchaResult = await validateCaptcha(captchaData.captchaId, captchaInput);
    if (!captchaResult.isValid) {
      setCaptchaError(captchaResult.message || "Incorrect CAPTCHA."); // Set the CAPTCHA error if validation fails
      return; // Prevent form submission if CAPTCHA is incorrect
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      return;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Stop submission if there are errors
    }

    const userData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.telephone,
      bank_id: parseInt(formData.bankId),
      password: formData.password,
      is_active: true, // Assuming new users are active by default
      role_ids: formData.roles,
    };

    try {
      await registerUser(userData).unwrap();
      toast.success(`Successfully registered as ${userData.first_name} ${userData.last_name}`, {
        theme: "colored",
      });
      navigate(paths.cardLogin); // Redirect to login page after successful registration

      // Redirect
    } catch (error) {
      toast.error(`Failed to register: ${error.data?.detail}`, {
        theme: "colored",
      });
    }
  };

  const validateCaptcha = async (captchaId, captchaInput) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login/validate-captcha`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        captcha_id: captchaId, // Ensure parameter names match those expected by the API
        captcha_input: captchaInput,
      }),
    });

    if (response.ok) {
      const responseData = await response.json(); // Handling JSON response
      return { isValid: true, message: responseData.message };
    } else {
      const errorResponse = await response.json(); // Handling potential error message in JSON
      console.log("errorResponse", errorResponse);
      return { isValid: false, message: errorResponse.detail };
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // Update the form data state
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "bankId") {
      // Clear selected roles when bank changes
      setFormData((prev) => ({
        ...prev,
        roles: [],
      }));
    }
    if (name === "telephone") {
      const error =
        /^\d+$/.test(value) || value === "" ? null : "Telephone number must contain only digits.";
      setErrors((prev) => ({ ...prev, telephone: error }));
    }
  };
  const handleRolesChange = (selectedRoles) => {
    setFormData((prev) => ({
      ...prev,
      roles: selectedRoles ? selectedRoles.map((role) => role.value) : [],
    }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>First Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "First Name" : ""}
          value={formData.firstName}
          name="firstName"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Last Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Last Name" : ""}
          value={formData.lastName}
          name="lastName"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Telephone</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Telephone" : ""}
          value={formData.telephone}
          name="telephone"
          onChange={handleFieldChange}
          type="text"
          isInvalid={!!errors.telephone}
        />
        <Form.Control.Feedback type="invalid">{errors.telephone}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Bank</Form.Label>}
        <Form.Control
          as="select"
          name="bankId"
          value={formData.bankId}
          onChange={handleFieldChange}
          isInvalid={!!errors.bankId}
        >
          <option value="">{dashes}</option>
          {banksData?.data.items.map((bank) => (
            <option key={bank.id} value={bank.id}>
              {bank.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{errors.bankId}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Roles</Form.Label>}
        <Select
          isMulti
          name="roles"
          options={rolesData?.map((role) => ({ value: role.id, label: role.name }))}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={dashes}
          onChange={handleRolesChange}
          value={rolesData
            ?.filter((role) => formData.roles.includes(role.id))
            .map((role) => ({ value: role.id, label: role.name }))}
          isDisabled={!formData.bankId || rolesLoading}
        />
        <Form.Control.Feedback type="invalid">{errors.roles}</Form.Control.Feedback>
        {formData.roles.length > 0 && (
          <div className="mt-2">
            <p
              style={{
                fontSize: "0.9rem",
                color: "blue",
                marginBottom: "0.2rem",
                padding: "0.2rem",
              }}
            >
              For the selected roles you will be able to:
            </p>
            <ul>
              {rolesData
                ?.filter((role) => formData.roles.includes(role.id))
                .map((role) => (
                  <li key={role.id} style={{ fontSize: "0.9rem", color: "blue" }}>
                    {role.description}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Password" : ""}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Confirm Password" : ""}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Label>CAPTCHA</Form.Label>
        <div>
          <img
            src={captchaData.captchaUrl}
            alt="CAPTCHA"
            onClick={fetchCaptcha}
            style={{ cursor: "pointer", marginBottom: "10px" }}
            title="Click to reload CAPTCHA"
          />
          <Form.Control
            type="text"
            placeholder="Enter CAPTCHA here"
            value={captchaInput}
            onChange={(e) => setCaptchaInput(e.target.value)}
            isInvalid={!!captchaError}
          />
          <Form.Control.Feedback type="invalid">{captchaError}</Form.Control.Feedback>
        </div>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.firstName ||
            !formData.lastName ||
            !formData.email ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.bankId ||
            Object.values(errors).some((error) => error !== null)
          }
        >
          Register
        </Button>
      </Form.Group>
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default RegistrationForm;
