import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useChangePasswordMutation } from 'components/services/authorization/authService';
import paths from 'routes/paths';
import messages from 'components/utilities/sedra3/messages';


const PasswordChangeForm = ({ hasLabel, handleClose }) => {
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      toast.error(messages.PASSWORDS_DO_NOT_MATCH, { theme: 'colored' });
      return;
    }

    try {
      const data = await changePassword({
        current_password: formData.currentPassword,
        new_password: formData.newPassword,
        confirm_password: formData.confirmPassword
      }).unwrap();

      toast.success(data.message, { theme: 'colored' });
      handleClose(); // Close the modal on success
    } catch (error) {
      toast.error(error.data?.detail || messages.PASSWORD_RESET_FAILURE, { theme: 'colored' });
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Current Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Current Password' : ''}
          value={formData.currentPassword}
          name="currentPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.newPassword}
          name="newPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={isLoading || !formData.currentPassword || !formData.newPassword || !formData.confirmPassword}
      >
        Set password
      </Button>

      <Link className="d-block mt-3 text-center" to={paths.simpleForgotPassword}>
        Forget password?
      </Link>
    </Form>
  );
};

PasswordChangeForm.propTypes = {
  hasLabel: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

export default PasswordChangeForm;
