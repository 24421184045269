import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import useAdvanceTable from "hooks/useAdvanceTable";
import {
  useDeleteApplicationMutation,
  useGetApplicationByIdQuery,
} from "components/services/application/ApplicationService";

const ActiveCases = ({
  applications,
  onEdit,
  onDetail,
  refetchApplications,
  refetchCallApplications,
  submittedApplications, // New prop to track submitted applications
}) => {
  const [deleteApplication] = useDeleteApplicationMutation();
  const [viewedApplicationId, setViewedApplicationId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch the application data when viewedApplicationId changes
  const { data: applicationData, refetch } = useGetApplicationByIdQuery(viewedApplicationId, {
    skip: !viewedApplicationId, // Skip query if no application is selected
  });

  useEffect(() => {
    if (showModal && viewedApplicationId) {
      refetch(); // Refetch the application data when the modal opens
    }
  }, [showModal, viewedApplicationId, refetch]);

  const handleDelete = async (id) => {
    try {
      await deleteApplication(id).unwrap();
      refetchApplications();
      refetchCallApplications();
    } catch (error) {
      console.error("Failed to delete application", error);
    }
  };

  const renderTooltip = (message) => <Tooltip>{message}</Tooltip>;

  const IconWithTooltip = forwardRef(({ icon, tooltip, onClick, className }, ref) => (
    <OverlayTrigger placement="top" overlay={renderTooltip(tooltip)}>
      <span ref={ref} onClick={onClick} className={className}>
        <FontAwesomeIcon icon={icon} />
      </span>
    </OverlayTrigger>
  ));

  const columns = [
    {
      accessorKey: "type",
      header: "Type",
      cell: ({ row }) => (
        <span
          className="text-primary cursor-pointer"
          onClick={() => onDetail(row.original.id, row.original.call_application_id)}
        >
          Application
        </span>
      ), // Since all rows are for applications
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "id",
      header: "Number",
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "created_at",
      header: "Created",
      cell: ({ getValue }) => new Date(getValue()).toLocaleDateString(),
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "updated_at",
      header: "Last update",
      cell: ({ getValue }) => new Date(getValue()).toLocaleDateString(),
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "user_action_req",
      header: "User actions required",
      cell: ({ getValue }) => (getValue() ? "Yes" : "No"),
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "comment",
      header: "Comments",
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const isSubmitted = row.original.status === "Submitted"; // Check if the application is submitted
        return !isSubmitted ? ( // Hide buttons if the application is submitted
          <div className="d-flex justify-content-around">
            <IconWithTooltip
              icon={faEdit}
              tooltip="Edit"
              onClick={() => onEdit(row.original.id, row.original.call_application_id)} // Use onEdit passed from parent
              className="text-primary cursor-pointer"
            />
            <IconWithTooltip
              icon={faTrashAlt}
              tooltip="Delete"
              onClick={() => handleDelete(row.original.id)}
              className="text-danger cursor-pointer"
            />
          </div>
        ) : null;
      },
      meta: {
        headerProps: { className: "text-900" },
      },
    },
  ];

  const table = useAdvanceTable({
    data: applications,
    columns,
    sortable: true,
    pagination: false,
  });

  return (
    <Card>
      <Card.Header>
        <h5>Active Cases</h5>
      </Card.Header>
      <Card.Body>
        <AdvanceTableProvider {...table}>
          <div className="table-responsive scrollbar">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
          </div>
        </AdvanceTableProvider>
      </Card.Body>
    </Card>
  );
};

ActiveCases.propTypes = {
  applications: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired, // Add onDetail as required prop
  refetchApplications: PropTypes.func.isRequired,
  refetchCallApplications: PropTypes.func.isRequired,
};

export default ActiveCases;
