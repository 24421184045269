import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../authorization/authService';

export const fileApi = createApi({
	reducerPath: 'fileApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['File', 'FileCategory'],
	endpoints: (builder) => ({
		getFilesByApplicationId: builder.query({
			query: (applicationId) => ({
				url: `/application_file/${applicationId}/files`,
				method: 'GET',
			}),
			providesTags: (result, error, applicationId) => [{ type: 'File', applicationId }],
		}),
		getFileCategories: builder.query({
			query: () => ({
				url: `/application_file/categories`,
				method: 'GET',
			}),
			providesTags: ['FileCategory'],
		}),
		createFileWithUpload: builder.mutation({
			query: ({ applicationId, fileData }) => ({
				url: `/application_file/${applicationId}/create_with_file`,
				method: 'POST',
				body: fileData,
			}),
			invalidatesTags: (result, error, { applicationId }) => [{ type: 'File', applicationId }],
		}),
		deleteFile: builder.mutation({
			query: ({ fileId }) => ({
				url: `/application_file/file/${fileId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, { applicationId }) => [{ type: 'File', applicationId }],
		}),
	}),
});

export const {
	useGetFilesByApplicationIdQuery,
	useGetFileCategoriesQuery,
	useCreateFileWithUploadMutation,
	useDeleteFileMutation,
} = fileApi;
