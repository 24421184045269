import React from 'react';
import { useLoginUserMutation } from 'components/services/authorization/authService';
import LoginForm from 'components/authentication/LoginForm';
import AuthCardLayout from 'layouts/AuthCardLayout';
import paths from 'routes/paths';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from 'features/auth/authSlice';
import { toast } from 'react-toastify';
import { setAccessToken, setRefreshToken } from 'components/utilities/sedra3/auth';

const Login = () => {
  const [loginUser] = useLoginUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleLogin = async (email, password) => {

    try {
      const response = await loginUser({ email, password }).unwrap();
      const { access_token, refresh_token, user } = response.data;

      dispatch(setCredentials({
        user: user,
        accessToken: access_token,
      }));
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      localStorage.setItem('user', JSON.stringify(user));
      navigate('/')

      toast.success(`Logged in as ${email}`, {
        theme: 'colored'
      });

    } catch (error) {
      // Display error message from the backend
      toast.error(error.data?.detail || 'Failed to log in', {
        theme: 'colored'
      });
    }
  };

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Don't have an account?
          <br />
          <Link className="text-white text-decoration-underline" to={paths.cardRegister}>
            Register
          </Link>
        </p>
      }
    >
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel onSubmit={handleLogin} />
    </AuthCardLayout>
  );
};

export default Login;
