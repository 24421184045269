// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "components/services/authorization/authService";
import { callApplicationApi } from "components/services/call-application/CallApplicationService";
import { userApi } from "components/services/users/userService";
import { applicationApi } from "components/services/application/ApplicationService"; // Import the applicationApi
import authReducer, { setCredentials } from "features/auth/authSlice";
import { fileApi } from "components/services/application-file/ApplicationFile";
import { commentApi } from "components/services/comment/commentService";
import { feedbackApi } from "components/services/feedback/feedbackService";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [callApplicationApi.reducerPath]: callApplicationApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
      callApplicationApi.middleware,
      applicationApi.middleware,
      fileApi.middleware,
      commentApi.middleware,
      feedbackApi.middleware
    ),
});

const user = localStorage.getItem("user");
const accessToken = localStorage.getItem("accessToken");

if (user && accessToken) {
  store.dispatch(setCredentials({ user: JSON.parse(user), accessToken }));
}

// Optional: setup listeners for refetching queries on focus/reconnect
setupListeners(store.dispatch);
