import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import paths from 'routes/paths';


export const ProtectedRoute = ({ children, requiredRoles = [] }) => {
	const { isAuthenticated, user } = useSelector((state) => state.auth);
	const location = useLocation();

	if (!isAuthenticated) {
		return <Navigate to={paths.cardLogin} state={{ from: location }} replace />;
	}

	if (requiredRoles.length > 0 && user) {
		const userHasRequiredRole = user.roles.some(role => requiredRoles.includes(role.name));
		if (!userHasRequiredRole) {
			return <Navigate to={paths.error401} replace />;
		}
	}


	return children;
};
