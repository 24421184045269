import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Error401 = () => {
	return (
	<Card className="text-center">
		<Card.Body className="p-5">
		<div className="display-1 text-300 fs-error">401</div>
		<p className="lead mt-4 text-800 font-sans-serif fw-semibold">
				You are not authorized to access this page.
		</p>
		<hr />
		<p>
			Please make sure you have the correct permissions to access this page. If you think this is a mistake,
			<a href="mailto:info@example.com" className="ms-1">
			contact us
			</a>
				.
		</p>
		<Link className="btn btn-primary btn-sm mt-3" to="/">
			<FontAwesomeIcon icon={faHome} className="me-2" />
			Take me home
		</Link>
		</Card.Body>
	</Card>
);
};

export default Error401;
