import React, { useState } from "react";

const RoleTabNavigation = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      {/* Render the navigation tabs */}
      <ul className="role-tabs">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={activeTab === index ? "active" : ""}
            onClick={() => setActiveTab(index)}
            style={{ cursor: "pointer", padding: "10px", display: "inline-block" }}
          >
            {tab.name} {/* Render the name of the role in the tab */}
          </li>
        ))}
      </ul>

      {/* Render the content of the active tab */}
      <div className="tab-content">
        {tabs[activeTab].component} {/* Render the component of the selected role */}
      </div>
    </div>
  );
};

export default RoleTabNavigation;
