import React, { useEffect } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import NavbarTop from "components/navbar/top/NavbarTop";
import { useAppContext } from "providers/AppProvider";
import CallsForApplicationPage from "./CallApplicationComponent";
import WebSocketComponent from "components/websocket";

const CustomerLayout = () => {
  const { hash, pathname } = useLocation();
  const matchMainPage = useMatch("/"); // Adjust the path if necessary
  const {
    config: { isFluid, navbarPosition },
  } = useAppContext();

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
       {/* <WebSocketComponent /> TODO: for later */}
      <NavbarTop />
      {matchMainPage && <CallsForApplicationPage itemsPerPage={5} />}{" "}
      {/* Render only on main page */}
      <Outlet />
    </div>
  );
};

export default CustomerLayout;
