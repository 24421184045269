import React from 'react';
import { useSelector } from 'react-redux';

const Authorized = ({ roles, children }) => {
	const authState = useSelector((state) => state.auth);
	const userRoles = authState.user?.roles || [];

	const isAuthorized = roles.some(role => userRoles.some(userRole => userRole.name === role));

	return isAuthorized ? children : null;
};

export default Authorized;
