import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Button, Table, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  useDeleteFileMutation,
  useCreateFileWithUploadMutation,
  useGetFileCategoriesQuery,
} from "components/services/application-file/ApplicationFile";
import { getAccessToken } from "components/utilities/sedra3/auth";
import { fileDownolader } from "components/utilities/sedra3/file-downloader";

const FileTable = ({
  files,
  setFiles,
  applicationId,
  handleTempFileUpload,
  onValidationChange,
  isDetail,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [comment, setComment] = useState("");
  const [requiredCategories, setRequiredCategories] = useState([]);
  const [validationMessage, setValidationMessage] = useState("");
  const [validationMessageColor, setValidationMessageColor] = useState("");

  const [deleteFile] = useDeleteFileMutation();
  const [createFileWithUpload] = useCreateFileWithUploadMutation();
  const {
    data: categoryOptionsData,
    refetch: refetchFileCategories,
    isLoading: isLoadingCategories,
  } = useGetFileCategoriesQuery();

  useEffect(() => {
    refetchFileCategories();
  }, [refetchFileCategories]);

  useEffect(() => {
    if (categoryOptionsData) {
      const required = categoryOptionsData.data
        .filter((category) => category.is_required)
        .map((category) => ({ id: category.id, name: category.category_name }));
      setRequiredCategories(required);
    }
  }, [categoryOptionsData]);

  useEffect(() => {
    if (requiredCategories.length > 0) {
      validateCategories();
    }
  }, [files, requiredCategories]); // Make sure to trigger it only when both are set

  const validateCategories = () => {
    if (requiredCategories.length === 0) {
      return;
    }

    // Case when no files have been uploaded yet
    if (files.length === 0) {
      const missingCategoryNames = requiredCategories.map((category) => category.name).join(", ");
      setValidationMessage(`Not all required files uploaded (${missingCategoryNames})`);
      setValidationMessageColor("text-danger");
      onValidationChange(false);
      return;
    }
    const selectedCategoryIds = files.flatMap((file) =>
      file.categories ? file.categories.map((category) => category.id) : []
    );

    const missing = requiredCategories.filter(
      (requiredCategory) => !selectedCategoryIds.includes(requiredCategory.id)
    );

    if (missing.length > 0) {
      const missingCategoryNames = missing.map((category) => category.name).join(", ");
      setValidationMessage(`Not all required files uploaded (${missingCategoryNames})`);
      setValidationMessageColor("text-danger");
      onValidationChange(false);
    } else {
      setValidationMessage("All required files uploaded");
      setValidationMessageColor("text-success-emphasis");
      onValidationChange(true);
    }
  };

  const handleDelete = async (index) => {
    const fileToDelete = files[index];
    if (fileToDelete.id) {
      try {
        await deleteFile({ fileId: fileToDelete.id, applicationId }).unwrap();
        toast.success("File deleted successfully");
      } catch (error) {
        toast.error("Failed to delete file: " + error.message);
        return;
      }
    }

    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    validateCategories();
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload");
      return;
    }

    const newFile = {
      file: selectedFile,
      categories: selectedCategories.map((category) => ({
        id: category.value,
        category_name: category.label,
      })),
      comment,
      uploadedAt: new Date(),
    };

    if (applicationId) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append(
          "category",
          JSON.stringify(newFile.categories.map((category) => category.id))
        );
        formData.append("comment", comment);

        const uploadedFile = await createFileWithUpload({
          applicationId,
          fileData: formData,
        }).unwrap();
        toast.success("File added successfully");
        setFiles([...files, uploadedFile]);
        setShowUploadModal(false);
        setSelectedFile(null);
        setSelectedCategories([]);
        setComment("");
        validateCategories();
      } catch (error) {
        toast.error("Failed to upload file: " + error.message);
      }
    } else {
      // Handle temporary file upload (for create mode)
      handleTempFileUpload(newFile);
      setFiles([...files, newFile]);
      setShowUploadModal(false);
      setSelectedFile(null);
      setSelectedCategories([]);
      setComment("");
      validateCategories();
    }
  };

  const handleDownload = async (file_id) => {
    try {
      const token = getAccessToken();
      const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/application_file/file/download/${file_id}`;
      await fileDownolader(downloadUrl, token); // Use the utility function
    } catch (error) {
      toast.error("Failed to download file");
    }
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Files</h5>
          {!isDetail && ( // Disable add button in detail mode
            <Button variant="primary" onClick={() => setShowUploadModal(true)}>
              Add File
            </Button>
          )}
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover className="mt-1">
            <thead>
              <tr>
                <th>#</th>
                <th>File Name</th>
                <th>Category</th>
                <th>Date Uploaded</th>
                <th>Comments</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <OverlayTrigger overlay={<Tooltip>Download file</Tooltip>}>
                      <span>
                        <a href="#" onClick={() => handleDownload(file.id)}>
                          {file.file?.name || file.file_path?.split("/").pop()}
                        </a>
                      </span>
                    </OverlayTrigger>
                  </td>
                  <td>
                    {file.categories
                      ? file.categories.map((category) => category.category_name).join(", ")
                      : ""}
                  </td>
                  <td>
                    {file.uploadedAt
                      ? file.uploadedAt.toLocaleDateString()
                      : new Date(file.created_at).toLocaleDateString()}
                  </td>
                  <td>{file.comment}</td>
                  <td>
                    {!isDetail && ( // Disable delete button in detail mode
                      <OverlayTrigger overlay={<Tooltip>Delete file</Tooltip>}>
                        <span>
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="text-danger cursor-pointer"
                            onClick={() => handleDelete(index)}
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {validationMessage && (
            <div className={`mt-3 ${validationMessageColor}`}>{validationMessage}</div>
          )}
        </Card.Body>
      </Card>

      <Modal
        show={showUploadModal}
        onHide={() => setShowUploadModal(false)}
        className="centered-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFile">
              <Form.Label>File</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Form.Group controlId="formCategory" className="mt-3">
              <Form.Label>Category</Form.Label>
              {isLoadingCategories ? (
                <div>Loading categories...</div>
              ) : (
                <Select
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  options={
                    Array.isArray(categoryOptionsData?.data)
                      ? categoryOptionsData.data.map((category) => ({
                          value: category.id,
                          label: category.category_name,
                        }))
                      : []
                  }
                  isMulti
                />
              )}
            </Form.Group>
            <Form.Group controlId="formComment" className="mt-3">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FileTable.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  applicationId: PropTypes.number,
  handleTempFileUpload: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  isDetail: PropTypes.bool.isRequired,
};

export default FileTable;
