import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import messages from 'components/utilities/sedra3/messages';
import { useResetPasswordMutation } from 'components/services/authorization/authService';

const PasswordResetForm = ({ hasLabel }) => {
  // State
  const location = useLocation();
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get('token');
    if (tokenParam) setToken(tokenParam);
  }, [location]);

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      toast.error(messages.PASSWORDS_DO_NOT_MATCH, { theme: 'colored' });
      return;
    }

    try {
      const data = await resetPassword({
        new_password: formData.password,
        confirm_password: formData.confirmPassword,
        token
      }).unwrap();

      toast.success(data.message, { theme: 'colored' });
      navigate(paths.cardLogin);
    } catch (error) {
      toast.error(error.data?.detail || messages.PASSWORD_RESET_FAILURE, { theme: 'colored' });
    }
  };


  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword}
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
