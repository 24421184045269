import React from 'react';  // Sedra3 added
import App from 'App';  // Sedra3 added
import paths, { rootPaths } from './paths'; // Sedra3 added
import { Navigate, createBrowserRouter } from 'react-router-dom'; // Sedra3 added

import AuthSimpleLayout from '../layouts/AuthSimpleLayout';   // Sedra3 added
import MainLayout from '../layouts/MainLayout';   // Sedra3 added
import ErrorLayout from '../layouts/ErrorLayout';
import WizardAuth from 'components/authentication/wizard/WizardAuth';

import Profile from 'components/pages/user/profile/Profile';

import Settings from 'components/pages/user/settings/Settings';


import Error404 from 'components/errors/Error404';  // Sedra3 added
import Error500 from 'components/errors/Error500';    // Sedra3 added
import Error401 from 'components/errors/Error401';  // Sedra3 added

import SimpleLogin from 'components/authentication/simple/Login'; // Sedra3 added
import SimpleLogout from 'components/authentication/simple/Logout'; // Sedra3 added
import SimpleRegistration from 'components/authentication/simple/Registration'; // Sedra3 added
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword'; // Sedra3 added
import SimplePasswordReset from 'components/authentication/simple/PasswordReset'; // Sedra3 added
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail'; // Sedra3 added
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
import CardLogin from 'components/authentication/card/Login'; // Sedra3 added
import CardLogout from 'components/authentication/card/Logout'; // Sedra3 added
import CardRegistration from 'components/authentication/card/Registration'; // Sedra3 added
import CardForgetPassword from 'components/authentication/card/ForgetPassword'; // Sedra3 added
import CardConfirmMail from 'components/authentication/card/ConfirmMail'; // Sedra3 added
import CardPasswordReset from 'components/authentication/card/PasswordReset'; // Sedra3 added
import CardLockScreen from 'components/authentication/card/LockScreen';

// SEDRA3
import { ProtectedRoute } from 'components/ProtectedRoute'; // Sedra3 added
import UserManagement from 'components/pages/user/profile/UserManagement';  // Sedra3 added
import CallApplication from 'components/pages/user/profile/CallApplication';  // Sedra3 added
import MainLayoutSelector from 'layouts/MainLayoutSelector';
import FeedbackForm from 'components/dashboards/SEDRA3/FeedbackForm';
////  *** ////

const routes = [
  {
    element: <App />,
    children: [

      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          },
          {
            path: paths.error401,
            element: <Error401 />
          }
        ]
      },
      {
        path: rootPaths.authRoot,
        children: [
          {
            path: rootPaths.authSimpleRoot,
            element: <AuthSimpleLayout />,
            children: [
              {
                path: paths.simpleLogin,
                element: <SimpleLogin />
              },
              {
                path: paths.simpleRegister,
                element: <SimpleRegistration />
              },
              {
                path: paths.simpleLogout,
                element: <SimpleLogout />
              },
              {
                path: paths.simpleForgotPassword,
                element: <SimpleForgetPassword />
              },
              {
                path: paths.simpleResetPassword,
                element: <SimplePasswordReset />
              },
              {
                path: paths.simpleConfirmMail,
                element: <SimpleConfirmMail />
              },
              {
                path: paths.simpleLockScreen,
                element: <SimpleLockScreen />
              }
            ]
          },
          {
            path: rootPaths.authCardRoot,
            children: [
              {
                path: paths.cardLogin,
                element: <CardLogin />
              },
              {
                path: paths.cardRegister,
                element: <CardRegistration />
              },
              {
                path: paths.cardLogout,
                element: <CardLogout />
              },
              {
                path: paths.cardForgotPassword,
                element: <CardForgetPassword />
              },
              {
                path: paths.cardResetPassword,
                element: <CardPasswordReset />
              },
              {
                path: paths.cardConfirmMail,
                element: <CardConfirmMail />
              },
              {
                path: paths.cardLockScreen,
                element: <CardLockScreen />
              }
            ]
          },

          {
            path: paths.authWizard,
            element: <WizardAuth />
          }
        ]
      },
      {
        path: '/',
        element: (<ProtectedRoute>
          <MainLayoutSelector />
        </ProtectedRoute>),
        children: [


          {
            path: rootPaths.userRoot,
            children: [
              {
                path: paths.userProfile,
                element: <Profile />
              },
              {
                path: paths.userManagement,
                element: (
                  <ProtectedRoute requiredRoles={['Portal Administrator', 'Bank Administrator']}>
                    <UserManagement />
                  </ProtectedRoute>
                )
              },
              {
                path: paths.callApplication,
                element: (
                  <ProtectedRoute requiredRoles={['Portal Administrator']}>
                    <CallApplication />
                  </ProtectedRoute>
                )
              },
              {
                path: paths.userSettings,
                element: <Settings />
              },
              {
                path: paths.feedback,
                element: <FeedbackForm />
              }
            ]
          },
        ]
      },
      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
