import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { usePasswordResetRequestMutation } from 'components/services/authorization/authService';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [passwordResetRequest, { isLoading }] = usePasswordResetRequestMutation();

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const data = await passwordResetRequest(email).unwrap();

        toast.success(data.message, {
          theme: 'colored'
        });
      } catch (error) {
        toast.error(`Failed to send reset link: ${error.data?.detail || email}`, {
          theme: 'colored'
        });
      }
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Send reset link
        </Button>
      </Form.Group>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
