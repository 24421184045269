import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../authorization/authService";

export const callApplicationApi = createApi({
  reducerPath: "callApplicationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["CallApplication"],
  endpoints: (builder) => ({
    getCallApplications: builder.query({
      query: () => ({
        url: "/call_application",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.items.map(({ id }) => ({ type: "CallApplication", id })),
              { type: "CallApplication", id: "LIST" },
            ]
          : [{ type: "CallApplication", id: "LIST" }],
    }),
    getFilteredCallApplications: builder.query({
      query: ({ bank_id, filterEndDateNull, page, size }) => ({
        url: "/call_application/customer/list",
        method: "GET",
        params: {
          bank_id,
          filter_end_date_null: filterEndDateNull,
          page,
          size,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.items.map(({ id }) => ({ type: "CallApplication", id })),
              { type: "CallApplication", id: "LIST" },
            ]
          : [{ type: "CallApplication", id: "LIST" }],
    }),

    getCallApplicationById: builder.query({
      query: (id) => ({
        url: `/call_application/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "CallApplication", id }],
    }),
    
    updateCallApplication: builder.mutation({
      query: ({ id, call_application_in }) => ({
        url: `/call_application/${id}`,
        method: "PUT",
        body: call_application_in,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "CallApplication", id }],
    }),

    createCallApplication: builder.mutation({
      query: (call_application_in) => ({
        url: "/call_application",
        method: "POST",
        body: call_application_in,
      }),
      invalidatesTags: [{ type: "CallApplication", id: "LIST" }],
    }),

    // New mutation for updating call application status
    updateCallApplicationStatus: builder.mutation({
      query: ({ id, new_status }) => ({
        url: `/call_application/${id}/status`,
        method: "PUT",
        params: {
          new_status,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "CallApplication", id }],
    }),
  }),
});

export const {
  useGetCallApplicationsQuery,
  useGetFilteredCallApplicationsQuery,
  useGetCallApplicationByIdQuery,
  useUpdateCallApplicationMutation,
  useCreateCallApplicationMutation,
  useUpdateCallApplicationStatusMutation,
} = callApplicationApi;
